<template>
  <div class="sendMessage">
    <!-- 顶部导航 -->
    <van-nav-bar
      title="短信发送"
      left-text="返回"
      left-arrow
      fixed
      placeholder
      safe-area-inset-top
      @click-left="$router.back()"
    />
    <van-form
      class="messageContent"
      ref="myForm"
      @submit="onSubmit"
    >
      <van-field
        readonly
        clickable
        is-link
        required
        center
        input-align="right"
        name="selectMessageModel"
        :value="value"
        label="短信类型"
        placeholder="模板类型"
        @click="showSelectMessageModel = true"
        :rules="[
          {
            required: true,
            message: '请选择短信模板',
            trigger: 'onSubmit',
          },
        ]"
      />
      <van-popup
        v-model="showSelectMessageModel"
        position="bottom"
        safe-area-inset-bottom
      >
        <van-picker
          title="选择短信模板"
          show-toolbar
          :columns="columns"
          @confirm="onSelectMessageModel"
          @cancel="showSelectMessageModel = false"
        />
      </van-popup>
      <p class="label">短信内容预览</p>
      <van-field
        v-model="message"
        rows="4"
        autosize
        type="textarea"
        readonly
        border
        name="message"
      />
      <van-field
        v-if="selectMessageModelContext.includes('time')"
        readonly
        clickable
        is-link
        required
        center
        input-align="right"
        name="selectParameterTime"
        :value="parameterTime"
        label="替换时间"
        label-class="myLabel"
        @click="isShowStartTimePicker = true"
        :rules="[
          {
            required: true,
            message: '请选择替换时间',
            trigger: 'onChange',
          },
          {
            validator: validatorEndTime,
            message: '请选择结束时间',
            trigger: 'onChange',
          },
          {
            validator,
            message: '结束时间不可小于开始时间',
            trigger: 'onChange',
          },
        ]"
      />
      <van-popup
        v-model="isShowStartTimePicker"
        position="bottom"
        safe-area-inset-bottom
      >
        <van-datetime-picker
          type="datetime"
          title="选择开始日期时间"
          @confirm="onConfirmStartTime"
          @cancel="isShowStartTimePicker = false"
          :min-date="minStartTime"
        />
      </van-popup>
      <van-popup
        v-model="isShowEndTimePicker"
        position="bottom"
        safe-area-inset-bottom
      >
        <van-datetime-picker
          type="datetime"
          title="选择结束日期时间"
          @confirm="onConfirmEndTime"
          @cancel="isShowEndTimePicker = false"
          :min-date="minEndTime"
          safe-area-inset-bottom
        />
      </van-popup>
      <van-field
        v-if="selectMessageModelContext.includes('people')"
        readonly
        clickable
        is-link
        required
        center
        input-align="right"
        name="selectInitiator"
        :value="initiator"
        label="替换人员"
        @click="selectInitiatorHandler(departmentCode)"
        :rules="[
          {
            required: true,
            message: '请选择替换人员',
            trigger: 'onChange',
          },
        ]"
      />
      <van-button
        type="info"
        native-type="submit"
        :loading="loading"
        loading-text="发送中..."
      >确认发送</van-button>
    </van-form>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { mapMutations, mapState } from 'vuex'
import { getMessageModel, sendMessage } from '@/api/sendMesage'

export default {
  name: 'sendMessage',
  data() {
    return {
      value: '',
      columns: [],
      showSelectMessageModel: false,
      isShowStartTimePicker: false,
      isShowEndTimePicker: false,
      minStartTime: new Date(),
      minEndTime: new Date(),
      startTime: '',
      endTime: '',
      selectMessageModelContext: '',
      message: '',
      departmentCode: '',
      id: '',
      loading: false
    }
  },
  beforeRouteEnter(to, from, next) {
    if (from.path.includes('/workTable/contactCommonAddressBook')) {
      next()
    } else {
      next(vm => {
        vm.getMessageModel()
      })
    }
  },
  // 路由离开页面
  beforeRouteLeave(to, from, next) {
    if (!to.path.includes('/workTable/contactCommonAddressBook')) {
      this.value = ''
      this.columns = []
      this.showSelectMessageModel = false
      this.isShowStartTimePicker = false
      this.isShowEndTimePicker = false
      this.minStartTime = new Date()
      this.minEndTime = new Date()
      this.startTime = ''
      this.endTime = ''
      this.selectMessageModelContext = ''
      this.message = ''
      this.id = ''
      this.setSelectList('')
      this.$refs.myForm.resetValidation()
    }
    next()
  },
  methods: {
    ...mapMutations('sendMessagePerson', ['setSelectList']),
    // 请选择结束时间
    validatorEndTime() {
      if (this.startTime) {
        return !!this.endTime
      }
    },
    // 结束时间不可小于开始时间
    validator() {
      if (this.endTime && this.startTime) {
        return (
          +dayjs(this.endTime).valueOf() - +dayjs(this.startTime).valueOf() >= 0
        )
      }
    },
    // 选择短信模板
    onSelectMessageModel(value) {
      this.value = value.text
      this.id = value.id
      this.departmentCode = value.departmentCode
      this.selectMessageModelContext = value.content
      this.message = value.content
      this.startTime = ''
      this.endTime = ''
      if (this.parameterTime) {
        this.message = this.message.replace(
          new RegExp(/\$\{time}/, 'gi'),
          match => {
            match = this.parameterTime
            return match
          }
        )
      }
      this.setSelectList('')
      this.showSelectMessageModel = false
    },
    // 开始时间选择
    onConfirmStartTime(time) {
      this.startTime = dayjs(time).format('YYYY/MM/DD HH:mm')
      this.isShowStartTimePicker = false
      this.isShowEndTimePicker = true
    },
    // 结束时间选择
    onConfirmEndTime(time) {
      this.endTime = dayjs(time).format('YYYY/MM/DD HH:mm')
      this.isShowEndTimePicker = false
    },
    // 获取短信模板
    async getMessageModel() {
      const { data } = await getMessageModel({ referenceLocation: 2 })
      this.columns = data.data.map(item => {
        item.text = item.title
        return item
      })
    },
    // 发送短信
    onSubmit(values) {
      this.loading = true

      sendMessage({
        parameterPeople: values.selectInitiator,
        parameterTime: `${dayjs(
          values.selectParameterTime.split('-')[0]
        ).format('YYYY-MM-DD HH:mm')}|${dayjs(
          values.selectParameterTime.split('-')[1]
        ).format('YYYY-MM-DD HH:mm')}`,
        phone: this.$route.params.tel,
        templateId: this.id,
        userName: this.$route.params.name
      })
        .then(() => {
          this.loading = false
          this.$toast.success({
            message: '发送成功!',
            duration: 500
          })
          this.$router.back()
        })
        .catch(error => {
          this.loading = false
          if (error.response.data.status === 'E00030099') {
            this.$toast.fail({
              message: '短信发送失败，请稍后再试，如失败超过三次请联系管理员!',
              duration: 500
            })
            return
          }
          if (error.response.data.status === 'E00030098') {
            this.$toast.fail({
              message: '余额不足，请联系管理员!',
              duration: 500
            })
            return
          }
          this.$toast.fail({
            message: '发送失败!',
            duration: 500
          })
        })
    },
    // 选择替换人员
    selectInitiatorHandler(departmentCode) {
      this.$router.push(`/workTable/contactCommonAddressBook/${departmentCode}`)
    }
  },
  computed: {
    ...mapState('sendMessagePerson', ['selectList']),
    parameterTime() {
      if (!this.startTime && !this.endTime) {
        return (
          dayjs()
            .set('hour', 10)
            .set('minute', 0)
            .format('YYYY/MM/DD HH:mm') +
          '-' +
          dayjs()
            .set('hour', 16)
            .set('minute', 0)
            .format('YYYY/MM/DD HH:mm')
        )
      } else {
        return this.startTime + `${this.endTime ? '-' : ''}` + this.endTime
      }
    },
    initiator() {
      if (this.selectList) {
        return JSON.parse(this.selectList).name
      } else {
        return ''
      }
    }
  },
  watch: {
    startTime(val) {
      if (val) {
        this.minEndTime = new Date(val)
      } else {
        this.minEndTime = new Date()
      }
    },
    parameterTime: function (val, oldVal) {
      this.message = this.message.replace(new RegExp(oldVal, 'gi'), match => {
        match = val
        return match
      })
    },
    initiator: function (val, oldVal) {
      if (oldVal) {
        this.message = this.message.replace(new RegExp(oldVal, 'gi'), match => {
          match = val
          return match
        })
      } else {
        this.message = this.message.replace(
          new RegExp(/\$\{people}/, 'gi'),
          match => {
            match = this.initiator
            return match
          }
        )
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.sendMessage {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: auto;

  ::v-deep {
    .myLabel {
      margin-right: 0px;
    }

    .van-button--info {
      position: absolute;
      bottom: 40px;

      width: 90%;
      left: 50%;
      transform: translateX(-50%);
      background-color: #00a6f0;
      height: 35px;
    }

    .label {
      padding-left: 16px;
      padding-top: 10px;
      color: #646566;
      text-align: left;
      word-wrap: break-word;
      font-size: 14px;
    }
  }
}
</style>
