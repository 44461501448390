import http from '@/units/request'
/** *
 * 获取短信模板
 * ***/
export const getMessageModel = (data) => {
  return http({
    url: `/message/template/v2/list/${'1'}`,
    method: 'get',
    params: data
  })
}
/** *
 * 获取替换人员
 * ***/
export const getUserMobileList = (data) => {
  return http({
    url: '/is-system/users/v2/getUserListByDepartmentCode',
    method: 'get',
    params: data
  })
}
/** *
 * 发送短信
 * ***/
export const sendMessage = (data) => {
  return http({
    url: '/message/externalSmsSessage/v2/send',
    method: 'post',
    data
  })
}
/** *
 * 确认短信时间
 * ***/
export const check24hour = (phone) => {
  return http({
    url: `/message/externalSmsSessage/v2/check24hour/${phone}`,
    method: 'get'
  })
}
