var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sendMessage"},[_c('van-nav-bar',{attrs:{"title":"短信发送","left-text":"返回","left-arrow":"","fixed":"","placeholder":"","safe-area-inset-top":""},on:{"click-left":function($event){return _vm.$router.back()}}}),_c('van-form',{ref:"myForm",staticClass:"messageContent",on:{"submit":_vm.onSubmit}},[_c('van-field',{attrs:{"readonly":"","clickable":"","is-link":"","required":"","center":"","input-align":"right","name":"selectMessageModel","value":_vm.value,"label":"短信类型","placeholder":"模板类型","rules":[
        {
          required: true,
          message: '请选择短信模板',
          trigger: 'onSubmit',
        },
      ]},on:{"click":function($event){_vm.showSelectMessageModel = true}}}),_c('van-popup',{attrs:{"position":"bottom","safe-area-inset-bottom":""},model:{value:(_vm.showSelectMessageModel),callback:function ($$v) {_vm.showSelectMessageModel=$$v},expression:"showSelectMessageModel"}},[_c('van-picker',{attrs:{"title":"选择短信模板","show-toolbar":"","columns":_vm.columns},on:{"confirm":_vm.onSelectMessageModel,"cancel":function($event){_vm.showSelectMessageModel = false}}})],1),_c('p',{staticClass:"label"},[_vm._v("短信内容预览")]),_c('van-field',{attrs:{"rows":"4","autosize":"","type":"textarea","readonly":"","border":"","name":"message"},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}}),(_vm.selectMessageModelContext.includes('time'))?_c('van-field',{attrs:{"readonly":"","clickable":"","is-link":"","required":"","center":"","input-align":"right","name":"selectParameterTime","value":_vm.parameterTime,"label":"替换时间","label-class":"myLabel","rules":[
        {
          required: true,
          message: '请选择替换时间',
          trigger: 'onChange',
        },
        {
          validator: _vm.validatorEndTime,
          message: '请选择结束时间',
          trigger: 'onChange',
        },
        {
          validator: _vm.validator,
          message: '结束时间不可小于开始时间',
          trigger: 'onChange',
        },
      ]},on:{"click":function($event){_vm.isShowStartTimePicker = true}}}):_vm._e(),_c('van-popup',{attrs:{"position":"bottom","safe-area-inset-bottom":""},model:{value:(_vm.isShowStartTimePicker),callback:function ($$v) {_vm.isShowStartTimePicker=$$v},expression:"isShowStartTimePicker"}},[_c('van-datetime-picker',{attrs:{"type":"datetime","title":"选择开始日期时间","min-date":_vm.minStartTime},on:{"confirm":_vm.onConfirmStartTime,"cancel":function($event){_vm.isShowStartTimePicker = false}}})],1),_c('van-popup',{attrs:{"position":"bottom","safe-area-inset-bottom":""},model:{value:(_vm.isShowEndTimePicker),callback:function ($$v) {_vm.isShowEndTimePicker=$$v},expression:"isShowEndTimePicker"}},[_c('van-datetime-picker',{attrs:{"type":"datetime","title":"选择结束日期时间","min-date":_vm.minEndTime,"safe-area-inset-bottom":""},on:{"confirm":_vm.onConfirmEndTime,"cancel":function($event){_vm.isShowEndTimePicker = false}}})],1),(_vm.selectMessageModelContext.includes('people'))?_c('van-field',{attrs:{"readonly":"","clickable":"","is-link":"","required":"","center":"","input-align":"right","name":"selectInitiator","value":_vm.initiator,"label":"替换人员","rules":[
        {
          required: true,
          message: '请选择替换人员',
          trigger: 'onChange',
        },
      ]},on:{"click":function($event){return _vm.selectInitiatorHandler(_vm.departmentCode)}}}):_vm._e(),_c('van-button',{attrs:{"type":"info","native-type":"submit","loading":_vm.loading,"loading-text":"发送中..."}},[_vm._v("确认发送")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }